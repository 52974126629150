@font-face {
  font-family: 'Roboto';
  src: url('https://cdn.avt.pl/fonts/Roboto-Light.eot');
  src: url('https://cdn.avt.pl/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Roboto-Light.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Roboto-Light.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Roboto-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// Regular Roboto


@font-face {
  font-family: 'Roboto';
  src: url('https://cdn.avt.pl/fonts/Roboto-Regular.eot');
  src: url('https://cdn.avt.pl/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Roboto-Regular.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Roboto-Regular.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/Roboto-Regular.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Roboto-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('https://cdn.avt.pl/fonts/Roboto-Medium.eot');
  src: url('https://cdn.avt.pl/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Roboto-Medium.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Roboto-Medium.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/Roboto-Medium.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Roboto-Medium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


// Bold Roboto


@font-face {
  font-family: 'Roboto';
  src: url('https://cdn.avt.pl/fonts/Roboto-Bold.eot');
  src: url('https://cdn.avt.pl/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Roboto-Bold.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Roboto-Bold.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/Roboto-Bold.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Roboto-Bold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('https://cdn.avt.pl/fonts/Roboto-Black.eot');
  src: url('https://cdn.avt.pl/fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Roboto-Black.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Roboto-Black.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/Roboto-Black.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Roboto-Black.svg') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('https://cdn.avt.pl/fonts/RobotoCondensedBold.eot');
  src: url('https://cdn.avt.pl/fonts/RobotoCondensedBold.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/RobotoCondensedBold.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/RobotoCondensedBold.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/RobotoCondensedBold.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/RobotoCondensedBold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Condensed';
  src: url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.eot');
  src: url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/RobotoCondensedRegular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src: url('https://cdn.avt.pl/fonts/Oswald-ExtraLight.eot');
  src: url('https://cdn.avt.pl/fonts/Oswald-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Oswald-ExtraLight.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Oswald-ExtraLight.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/Oswald-ExtraLight.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Oswald-ExtraLight.svg') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src: url('https://cdn.avt.pl/fonts/Oswald-Light.eot');
  src: url('https://cdn.avt.pl/fonts/Oswald-Light.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Oswald-Light.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Oswald-Light.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/Oswald-Light.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Oswald-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src: url('https://cdn.avt.pl/fonts/Oswald-Regular.eot');
  src: url('https://cdn.avt.pl/fonts/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Oswald-Regular.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Oswald-Regular.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/Oswald-Regular.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Oswald-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Oswald';
  src: url('https://cdn.avt.pl/fonts/Oswald-Medium.eot');
  src: url('https://cdn.avt.pl/fonts/Oswald-Medium.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Oswald-Medium.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Oswald-Medium.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/Oswald-Medium.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Oswald-Medium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Oswald';
  src: url('https://cdn.avt.pl/fonts/Oswald-SemiBold.eot');
  src: url('https://cdn.avt.pl/fonts/Oswald-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Oswald-SemiBold.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Oswald-SemiBold.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/Oswald-SemiBold.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Oswald-SemiBold.svg') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Oswald';
  src: url('https://cdn.avt.pl/fonts/Oswald-Bold.eot');
  src: url('https://cdn.avt.pl/fonts/Oswald-Bold.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/Oswald-Bold.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/Oswald-Bold.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/Oswald-Bold.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/Oswald-Bold.svg') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Playfair Display';
  src: url('https://cdn.avt.pl/fonts/PlayfairDisplay-Regular.eot');
  src: url('https://cdn.avt.pl/fonts/PlayfairDisplay-Regular.eot?#iefix') format('embedded-opentype'),
  url('https://cdn.avt.pl/fonts/PlayfairDisplay-Regular.woff2') format('woff2'),
  url('https://cdn.avt.pl/fonts/PlayfairDisplay-Regular.ttf') format('ttf'),
  url('https://cdn.avt.pl/fonts/PlayfairDisplay-Regular.woff') format('woff'),
  url('https://cdn.avt.pl/fonts/PlayfairDisplay-Regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
    font-family: 'FontAwesome';
    src: url('https://cdn.avt.pl/fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('https://cdn.avt.pl/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
         url('https://cdn.avt.pl/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-facebook-square:before    { content: "\f082"; }
.fa-pinterest:before          { content: "\f0d2"; }
.fa-instagram:before          { content: "\f16d"; }
.fa-shopping-cart:before      { content: "\f07a"; }
.fa-newspaper-o:before        { content: "\f1ea"; }
.fa-search:before             { content: "\f002"; }
.fa-link:before               { content: "\f0c1"; }
.fa-font:before               { content: "\f031"; }
.fa-print:before              { content: "\f02f"; }
.fa-check:before              { content: "\f00c"; }
.fa-chevron-right:before      { content: "\f054"; }
.fa-chevron-left:before       { content: "\f053"; }
.fa-times:before              { content: "\f00d"; }
.fa-bars:before               { content: "\f0c9"; }
.fa-cloud:before              { content: "\f0c2"; }
.fa-leaf:before               { content: "\f06c"; }
.fa-plus-circle:before        { content: "\f055"; }
.fa-long-arrow-right:before   { content: "\f178"; }
.fa-plus:before               { content: "\f067"; }
.fa-times-circle:before       { content: "\f057"; }
.fa-envelope:before           { content: "\f0e0"; }
.fa-facebook:before           { content: "\f09a"; }
.fa-google-plus:before        { content: "\f0d5"; }
.fa-twitter:before            { content: "\f099"; }
.fa-comment:before            { content: "\f075"; }
.fa-th:before                 { content: "\f00a"; }
.fa-caret-down:before         { content: "\f0d7"; }
.fa-chevron-down:before       { content: '\f078'; }
.fa-external-link:before      { content: "\f08e"; }
