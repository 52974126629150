
/*Colors */

$color-black-0:     		#000;
$color-black-21:            #212121;
$color-black-13:            #131313;
$color-white:				#fff;
$color-gold:                #BEB7A8;
$color-gold-dark:           #958156;
$color-gray:                #D8D8D8;
$color-gray-dark:           #292C36;
$color-gray-font:           #474747;
$color-gray-light:          #D8D8D8;
$color-red:                 #E40000;
$color-green:               #227400;

$font-main: 'Roboto', sans-serif;
$font-second: 'Oswald', sans-serif;
$font-additional: 'Roboto Condensed', sans-serif;
$font-play: 'Playfair Display', sans-serif;

/* Kolory fontow */
$color-font-main:           $color-black-0;
$color-font-second:         $color-gray-font;


/*Breakpointy */
$xs-max:    575.98px;
$sm:        576px;
$sm-max:    767.98px;
$md:        768px;
$md-max:    1024.98px;
$lg:        1025px;
$lg-max:    1279.98px;
$xl:        1280px;


.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
