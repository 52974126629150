@import 'abstracts/variables';
@import 'base/typography';

*, *::before, *::after {
    box-sizing: border-box;
}
body { margin: 0; }

.error-page {
    width: 100%;
    min-height: 100vh;
    padding: 0 15px;
    background: url('../images/bg-error.jpg') 50%/cover no-repeat;
    color: #fff;
    font-family: 'Oswald';
    text-align: center;
}
.error {
    &-header {
        padding: 60px 0 88px;
        
        .logo {
            display: block;
            width: 111px;
            max-width: 100%;
            margin: 0 auto;
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    &-main {}

    &-message {
        margin: 0;
        color: #fff;
        font-size: 40px;
        font-weight: 600;
        @media(min-width: $md) {
            font-size: 84px;
        }
        
        span {
            display: block;
            font-size: 22px;
            font-weight: 200;
            letter-spacing: 12px;
            line-height: 1.4;
            @media(min-width: $md) {
                font-size: 29px;
                line-height: 1;
            }
        }
    }

    &-code {
        margin: 30px 0 0;
        font-size: 140px;
        font-weight: 700;
        line-height: 1;
        color: red;
        @media (min-width: $md) {
            font-size: 200px;
        }
    }
}

.search {
    width: 100%;
    max-width: 924px;
    margin: 40px auto 0;
    position: relative;
    z-index: 1;
    border: 7px solid #fff;
    
    &-form {
        display: flex;
    }
    &-input {
        flex: 1 1 auto;
        width: 1%;
        background: transparent;
        color: rgba(26, 27, 28, 1);
        height: 90px;
        padding: 0 10px 0 15px;
        border: none;
        font-size: 38px;
        outline: none;
        color: #fff;
        font-family: 'Oswald';
        @media(min-width: $md) {
            padding: 0 15px 0 30px;
        }
        
        &::placeholder {
            color: #fff;
            opacity: 1;
        }
        &:-ms-input-placeholder {
            color: #fff;
        }
        &::-ms-input-placeholder {
            color: #fff;
        }
    }

    &-button {
        flex: 0 0 104px;
        max-width: 104px;
        height: 90px;
        padding: 0;
        background: transparent;
        border: none;
        outline: none;
        
        svg {
            cursor: pointer;
            width: 43px;
            height: 43px;
            
            path {
                fill: #fff;
            }
        }
    }
}

.debug {
    margin-top: 30px;
    text-align: left;
    overflow-x: auto;
    background-color: $color-gray;
    padding: 30px 0;
    .table {
        margin: 0 auto;
        td       { padding: 2px 5px; }
        tr:hover { background: rgba($color: #fff, $alpha: 0.25); }
    }

    &-error {
        @extend .error-message;
        text-align: center;
        &-code { font-weight: 700; }
    }
}